const traitSpecs = {
    "Head": {
        "VLC": {
            "files": {
                "vlc.png": 90,
            },
            "probability": 4
        },
        "Summer": {
            "files": {
                "summer_hat.png": 90,
            },
            "probability": 4
        },
        "Deth Cone": {
            "files": {
                "deth_cone.png": 90,
            },
            "probability": 4
        },
        "Bloody Crown": {
            "files": {
                "bloody_crown.png": 90,
            },
            "probability": 4
        },
        "Dokuro Hat": {
            "files": {
                "dokuro.png": 90,
            },
            "probability": 4
        },
        "Assassin Mask": {
            "files": {
                "assassin_mask.png": 90,
            },
            "probability": 4
        },
        "Void Mask": {
            "files": {
                "void_mask.png": 90,
            },
            "probability": 4
        },
        "Prisoner": {
            "files": {
                "prisoner.png": 90,
            },
            "probability": 4
        },
        "Doom Cone": {
            "files": {
                "doom_cone.png": 90,
            },
            "probability": 4
        },
    },
    "Specials": {
        "Black Aura": {
            "files": {
                "black_aura.png": 20,
            },
            "probability": 4
        },
        "Bloody Aura": {
            "files": {
                "bloody_aura.png": 20,
            },
            "probability": 4
        },
        "Baby Demon": {
            "files": {
                "baby_demon.png": 20,
            },
            "probability": 4
        },
        "Pananita": {
            "files": {
                "panana1.png": 20,
            },
            "probability": 3
        },
        "Panana": {
            "files": {
                "panana2.png": 20,
            },
            "probability": 3
        },
        "Paniana Grande": {
            "files": {
                "panana3.png": 20,
            },
            "probability": 3
        },
        "Panillionaire": {
            "files": {
                "panana4.png": 20,
            },
            "probability": 3
        },
    },
    "Clothes": {
        "Backward Pants": {
            "files": {
                "backward_pants.png": 60,
            },
            "probability": 4
        },
        "Pape Jacket Akai": {
            "files": {
                "pape_jacket_akai.png": 60,
            },
            "probability": 4
        },
        "Pape Jacket Grey": {
            "files": {
                "pape_jacket_grey.png": 60,
            },
            "probability": 4
        },
        "Tactical Gloves": {
            "files": {
                "tactical_gloves.png": 60,
            },
            "probability": 4
        },
    },
    "Mouth": {
        "Calneca": {
            "files": {
                "calneca.png": 70,
            },
            "probability": 4
        },
    }
}

export default traitSpecs;