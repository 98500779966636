import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useReadContract, useWriteContract } from 'wagmi'
import { useState } from 'react';
import { useEffect } from 'react';
import { Tweet } from 'react-tweet';
import './styles/App.css';
import Nav from './components/Nav';
import Footer from './components/Footer';
import { Link } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;


const CONTRACT_ADDRESS = '0xe3150c35226e5afecc58f159d79d5bcc3f82e33f';


// const USER_ADDRESS = "0x08eea0716cfa4ca55c7829b74e45114847c028e3";


interface Item {
  tokenId: string;
  name: string;
  image: string;
  balance: number;
}

const App = () => {

  const [items, setItems] = useState<Item[]>([]);

  const { address, isConnected } = useAccount()

  async function getItems() {
    const res = await fetch(`${apiUrl}/items?address=${address}&contract=${CONTRACT_ADDRESS}`);
    const userItems = [];
    const result = await res.json();
    for (let x in result.items) {
      for (let y in result.items[x]) {
        if (parseInt(result.items[x][y].tokenId) >= 1) {
          userItems.push(result.items[x][y]);
        }
      }
    }
    let cleanedItems = [];
    for (let x in userItems) {
      let item = {
        tokenId: userItems[x].tokenId,
        name: userItems[x].name,
        image: userItems[x].image.pngUrl,
        balance: parseInt(userItems[x].balance)
      }
      cleanedItems.push(item)
    }
    setItems(cleanedItems);
  }

  useEffect(() => {
    if (isConnected) {
      getItems();
    }
  }, [isConnected]);

  return (
    <div className={"container"}>
      <main className={"main"}>

        <Nav />

        <h1 className={"mainHeading"}>Bomefers dashboard</h1>

        {!items || items.length == 0 ? (
          <div>
            <div>
              <p className={"cardBody"}>No items found</p>
            </div>
          </div>
        ) : (
          <div className={"inventoryContainer"}>
            {/* Map your grid items here */}
            {items.map((item) => (
              <Link to={"/dressing-room/" + `${item.tokenId}`} state={`${item.tokenId}`}>
                <div key={item.tokenId} className={"inventoryCard"}>
                  <img src={`https://storage.googleapis.com/bomefers/images/${item.tokenId}.png`} alt={item.name} className={"inventoryImage"} />
                  <a href={`https://opensea.io/assets/base/0xe3150c35226e5afecc58f159d79d5bcc3f82e33f/${item.tokenId}`} target='blank_'><p className={"cardHeading"}>Bomefer</p></a>
                  <a href={`https://opensea.io/assets/base/0xe3150c35226e5afecc58f159d79d5bcc3f82e33f/${item.tokenId}`} target='blank_'><p className={"cardBody"}>#{item.tokenId}</p></a>
                </div>
              </Link>
            ))}
          </div>
        )}

        <div className={"buyOnOpensea"}>
          <a href='https://opensea.io/collection/bomefers' style={{ color: "#1868B7" }} target='blank_'>Buy Bomefers on</a><a href='https://opensea.io/collection/bomefers' style={{ color: "#1868B7" }} target='blank_'><img className={"socialIcon"} src="images/Logomark-Blue.png" ></img></a>
        </div>

        <div className={"tweetSlider"}>
          <div className={"tweetContainer"}>
            <Tweet id="1863624505400213711" />
          </div>
          <div className={"tweetContainer"}>
            <Tweet id="1862525510741332392" />
          </div>
          <div className={"tweetContainer"}>
            <Tweet id="1859682539088249322" />
          </div>
          <div className={"tweetContainer"}>
            <Tweet id="1852487984748396620" />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default App;
