import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Link } from 'react-router-dom';

const Nav = () => {
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: '100%' }}>
            <img className={"logo"} src="/images/logo.png"></img>
            <div className={"menuLinks"}>
                <a href="/">Home</a>
                {/* <a href="/raffles">Raffles</a> */}
                {/* <a style={{color: "gold"}} href="/presale">$PANANA</a> */}
            </div>
            <ConnectButton />
        </div>
    )
}

export default Nav;